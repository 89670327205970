import staticContent from '@/components/static-content/index.vue';
import aboutMedia from './components/about-media/index.vue';
import {mapActions, mapGetters, mapMutations} from "vuex";
import Breadcrumbs from '@/components/breadcrumbs/index.vue';
import CustomBreadcrumbs from "@/components/custom-breadcrumbs/index.vue";

export default {
  name: 'about-us',
  components: {
    staticContent,
    aboutMedia,
    Breadcrumbs,
    CustomBreadcrumbs
  },
  data() {
    return {
      currentId: null,
      crumbs: [{title: this.$t('aboutUs'), slug: ''}]
    }
  },
  created() {
    setTimeout(()=>{
      this.staticLinks.forEach(el => {
        if (parseInt(el.id )=== parseInt(this.$route.query.id)) {
          this.crumbs = [{title: this.$t('aboutUs'), slug: `id=${this.$route.query.id}`}]
          this.crumbs.push({title: el.title, slug: ''})
        }
        if (this.$route.query.id === 'media') {
          this.crumbs = [{title: this.$t('aboutUs'), query: {id: 'media', page: '1'}}]
          this.crumbs.push({title: this.$t('aboutUsMedia'), slug: ''})
        }
      })
    },1000)
  },
  mounted() {
  },
  beforeUpdate() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  },
  computed: {
    ...mapGetters({
      staticLinks: `about/staticLinks`,
      isLoadingStaticLinks: `about/isLoadingStaticLinks`,
      staticList: `about/staticList`,
      isLoadingStaticList: `about/isLoadingStaticList`,
      mediaList: `about/mediaList`,
    }),
    ...mapGetters([
      'languages',
      'currentLanguage',

    ]),
  },
  watch: {
    '$route.query.id': {
      immediate: true,

      handler: function (id) {
        this.staticLinks.forEach(el => {
          if (el.id === id) {
            this.crumbs = [{title: this.$t('aboutUs'), slug: '?id=3',},]
            this.crumbs.push({title: el.title, slug: ''})
          }
          if (this.$route.query.id === 'media') {
            this.crumbs = [{title: this.$t('aboutUs'), query: {id: 'media', page: '1'}}]
            this.crumbs.push({title: this.$t('aboutUsMedia'), slug: ''})
          }
        })
        if (id === undefined) {
          if (this.staticLinks.length) {
            this.currentId = this.staticLinks[0].id;
            this.$router.replace({name: 'about-us', query: {id: this.currentId}}).catch(() => {
              console.log()
            });
          } else {
            this.currentId = 'media';
            this.crumbs.push({title: this.$t('aboutUs'), slug: 'about-us'})
            this.$router.replace({name: 'about-us', query: {id: this.currentId, page: 1}}).catch(() => {
              console.log()
            });
          }
        } else {
          this.currentId = id;
          if (this.currentId === 'media' && this.$route.query.page == undefined) {
            this.$router.replace({name: 'about-us', query: {id: this.currentId, page: 1}}).catch(() => {
              console.log()
            });
          } else if (this.currentId !== 'media' && !this.staticList.length) {
            this.fetchStaticList(true).then(() => {
              if (!this.staticList.length) {
                this.$router.replace({name: 'about-us', query: {id: 'media', page: 1}}).catch(() => {
                  console.log()
                });
              }
            });
          }
        }
      }
    },
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      fetchStaticList: `about/ABOUT_STATIC_GET`,
    }),
    showCurrentTab(id) {
      this.$router.replace({name: 'about-us', query: {id: id}}).catch(() => {
        console.log();
      });
    }
  },

}
