import moment from "moment";
import {mapMutations} from "vuex";

export default {
  name: 'card-media',
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {},
  data() {
    return {}
  },
  computed: {
    cardDate() {
      return this.item && this.item.created_at ? moment(this.item.created_at).format('DD.MM.YYYY') : null;
    }
  },
  methods: {
    ...mapMutations({
      saveMediaContent: 'about/SAVE_MEDIA_CONTENT'
    }),
    getCurrentArticle(item) {
      if (this.$route.query.id === 'media') {
        this.saveMediaContent(item)
        this.$router.push({name: 'media',params:{slug:item.slug}}).then(() => {
          console.log()
        });
      } else {
        this.$router.push({
          name: 'current-article',
          params: {id: item.id},
          query: {page: this.$route.query.page, title: item.title}
        });
      }


    }
  }
}
